<template>
<div>
  <!-- <Header1></Header1> -->
  <div class="content">
    <!-- 表单 -->
    <el-form :model="ruleFormAdd" :rules="rules" ref="ruleFormAdd" label-width="100px" class="demo-ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleFormAdd.title"></el-input>
      </el-form-item>
      <el-form-item label="摘要" prop="description">
        <el-input type="textarea" v-model="ruleFormAdd.description"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="context">
        <!-- 使用mavon-editor组件的编辑区域 -->
        <mavon-editor v-model="ruleFormAdd.context"></mavon-editor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleFormAdd')">提交</el-button>
        <el-button @click="resetForm('ruleFormAdd')">重置</el-button>
        <el-button @click="cancel">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>


<script>
// import Header from "../components/Header.vue"  ;
// import Header1 from "../components/Header1.vue"  ;
export default {
  name : 'BlogEdit' , 
  // components:{Header ,Header1} , 
  data() {
        return {
          ruleFormAdd: {
            id:'' ,
            title: '',
            description: '' , 
            context:''
          },
          rules: {
            title: [
              { required: true, message: '请输入文章标题', trigger: 'blur' },
              { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
            ],
            description: [
              { required: true, message: '请填写文章摘要', trigger: 'blur' }
            ],
            context: [
              { required: true, message: '请填写文章内容', trigger: 'blur' }
            ]
          }
        };
      },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this ; 
          this.$axios.post('/blog/edit' , this.ruleFormAdd , {
            headers:{
              "Authorization":localStorage.getItem("token")
            }
          }).then(res => {
            this.$alert('操作成功', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                _this.$router.push('/manage/articles') ; 
              }
            });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    } , 
    cancel(){
      
      // this.$router.push('/manage/articles') ; 
    }
  } , 
  created(){
    const _this = this ; 
    const blogId = this.$route.params.blogId ; 
    // console.log(blogId);
    if (blogId) {
      this.$axios.get('/blog/' + blogId ).then(res => {
        const blog = res.data.data ; 
          _this.ruleFormAdd.id = blog.id ; 
          _this.ruleFormAdd.title = blog.title ; 
          _this.ruleFormAdd.description = blog.description ; 
          _this.ruleFormAdd.context = blog.context ; 
      })
    }
  }
}
</script>


<style scoped>
.content{
  width: 83rem;
  /* height: 100vh; */
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  padding: 50px 50px 50px 0;
  background-color: pink;
}
</style>