<template>
  <div class="wrapper">
    <!-- 文章管理 -->
    <el-card class="card-1">
      <div class="header"><h2>文章管理</h2></div>
      <!-- ===================================表单区域============================= -->
      <el-form :model="ruleForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div class="form-1">
          <el-form-item label="标题：" class="form-item">
            <el-input v-model="ruleForm.query"></el-input>
          </el-form-item>
          <el-form-item label="分类：" class="form-item">
            <el-input v-model="ruleForm.category"></el-input>
          </el-form-item>
          <el-form-item label="标签：" class="form-item">
            <el-input v-model="ruleForm.tag"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="发表时间：" class="form-2">
          <el-col :span="10">
            <el-date-picker type="date" placeholder="发表时间从" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
          </el-col>
          <el-col class="line" :span="-1">&#160; - &#160;</el-col>
          <el-col :span="10">
            <el-date-picker type="date" placeholder="发表时间到" v-model="ruleForm.date2" style="width: 100%;"></el-date-picker>
          </el-col>
          <div class="query-btn">
            <el-button type="primary" size="" @click="submitFormQuery()" >查询</el-button>
            <el-button @click="resetFormQuery('ruleForm')">重置</el-button>
            <el-button type="success" @click="showAddBlog">添加博客</el-button>
          </div>
        </el-form-item>
      </el-form>
      <!-- ============================展示文章的表格============================ -->
      <div class="item-wrap" >
        <el-table :data="tableData" border style="width: 100%;" >
          <el-table-column type="index" width="40"></el-table-column>
          <el-table-column property="title" label="标题" width="150" :formatter="stateFormat"></el-table-column>
          <el-table-column property="description" label="摘要" width="200" :formatter="stateFormat"></el-table-column>
          <el-table-column property="context" label="内容" width="320" :formatter="stateFormat"></el-table-column>
          <el-table-column property="created"  label="发表时间" width="120"></el-table-column>
          <!-- <el-table-column label="姓名" width="180">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>姓名: {{ scope.row.name }}</p>
                <p>住址: {{ scope.row.address }}</p>
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium">{{ scope.row.name }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column> -->
          <el-table-column property="typeName" label="分类" width="100"></el-table-column>
          <el-table-column property="labelName" label="标签" width="100"></el-table-column>
          <el-table-column property="status" label="状态" width="80">
            <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.status"
                    active-value="0"
                    inactive-value="1"
                    @change="changeSwitch(scope.row,scope.$index)">
                  </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" >
            <template slot-scope="scope">
              <!-- <el-button size="mini" >查看</el-button> -->
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page-wrap">
          <!--handleSizeChange和handleCurrentChange页面监听方法，当页面发生变化时执行-->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total" class="page">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!-- ===============================添加文章的dialog=================================== -->
    <div class="addBlog" v-show="showAddBlogDialog">
      <div class="content">
        <!-- 表单 -->
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleFormAdd" label-width="100px" class="demo-ruleForm">
          <el-form-item label="标题" prop="title">
            <el-input v-model="ruleFormAdd.title"></el-input>
          </el-form-item>
          <el-form-item label="摘要" prop="description">
            <el-input type="textarea" v-model="ruleFormAdd.description"></el-input>
          </el-form-item>
          <el-form-item label="分类：" >
            <el-select v-model="category_value" @change="chooseTagByCate" prop="cateName" placeholder="请选择分类" class="cate_select">  <!--选择分类的下拉框-->
              <el-option
                v-for="item in category_options"
                :key="item.id"
                :label="item.typeName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签：" >
            <el-select v-model="tag_value" prop="tagName" placeholder="请选择标签" class="cate_select">  <!--选择分类的下拉框-->
              <el-option
                v-for="item in tag_options"
                :key="item.id"
                :label="item.labelName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="context">
            <!-- 使用mavon-editor组件的编辑区域 -->
            <mavon-editor v-model="ruleFormAdd.context"></mavon-editor>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleFormAdd')">提交</el-button>
            <el-button @click="resetForm('ruleFormAdd')">重置</el-button>
            <el-button @click="cancel">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="addBlogShallow" v-show="this.showAddBlogDialog"></div>
  </div>
</template>


<script>
import Add from "../BlogEdit.vue"  ;
export default {
  name : 'Article' , 
  components:{Add} , 
  data() {
    return {
      // 查询表单区域====================
      ruleForm:{} ,
      // 新增blog========================
      showAddBlogDialog:false , 
      ruleFormAdd: {
        id:'' ,
        title: '',
        description: '' , 
        context:'' , 
        typeId:'' , 
        labelId:''
      },
      rules: {
        title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 4, max: 100, message: '长度在 4 到 100 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请填写文章摘要', trigger: 'blur' }
        ],
        context: [
          { required: true, message: '请填写文章内容', trigger: 'blur' }
        ]
      } ,
      category_options: [],   // 添加blog时两个下拉框的值
      category_value: '',
      tag_options: [],
      tag_value: '',
      // 表格区域==============================
      total:0 , 
      queryInfo:{    // 查询信息实体
        title:"" ,   
        typeId:"" ,   
        labelId:"" ,   
        date1:"" ,   
        date2:"" ,   
        pageNum: 1 ,   // 设定起始页
        pageSize: 5    // 每页显示的数量
      } , 
      tableData: [], // 查询到的数据   
    }
  },
  methods: {
    // ===================== 查询区域 ====================
    // 查询方法
    async submitFormQuery() {
      // const{data:res} = await this.$axios.get("getBlogsList" , {params:this.queryInfo}) ;  
      // console.log(1111111111);
      // console.log(res);
      // console.log(11111111111);
      // this.tableData = res.data.data ;
      // this.total = res.data.numbers ; 
      console.log(this.ruleForm);
      console.log(1);
    },
    // 重置按钮
    resetFormQuery(formName) {
      this.ruleForm = {} ; 
      // this.$refs[formName].resetFields();
    } , 
    // ================================新增blog========================
    showAddBlog(){
      if(this.$store.state.userInfo){
        this.showAddBlogDialog = !this.showAddBlogDialog ; 
        this.getAllCategory() ; 
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this ; 
          console.log(this.category_value);
          this.ruleFormAdd.typeId = this.category_value ; 
          this.ruleFormAdd.labelId = this.tag_value ; 
          console.log(this.ruleFormAdd);
          this.$axios.post('/blog/edit' , this.ruleFormAdd , {
            headers:{
              "Authorization":localStorage.getItem("token")
            }
          }).then(res => {
            this.$alert('操作成功', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.ruleFormAdd = {} ; 
                this.showAddBlogDialog = !this.showAddBlogDialog ; 
                this.getBlogsList() ; 
                this.category_value = "" ; 
                this.tag_value = "" ; 
              }
            });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    } , 
    cancel(){
      this.showAddBlogDialog = !this.showAddBlogDialog ; 
      this.ruleFormAdd = {} ; 
      this.category_value = "" ; 
      this.tag_value = "" ; 
    } ,
    //获取所有分类
    getAllCategory(){
      this.$axios.get('/getTypes' , this.ruleForm).then(res =>{
        this.category_options = res.data.data ; 
        // this.getTagByCate() ; 
      })
    } , 
    //获取某个分类下的标签
    getTagByCate(flag){
      // let type_id = 1 ; 
      this.$axios.get('/getLabelByTypeId/'+ flag).then(res =>{
        console.log(res);
        this.tag_options = res.data.data ; 
      })
    } ,
    // 根据选中的分类展示标签  下拉框联动 
    chooseTagByCate(){
      console.log(this.category_value);
      let flag = this.category_value ; 
      this.getTagByCate(this.category_value) ; 
    } ,
    // ====================== 表格方法 ====================
    // 获取所有文章
    // page(currentPage){
    //   const _this = this ; 
    //   _this.$axios.get("/blogs?currentPage=" + currentPage).then(res => {
    //     _this.tableData = res.data.data.records ; 
    //     _this.currentPage = res.data.data.current ; 
    //     _this.total = res.data.data.total ; 
    //     _this.pageSize = res.data.data.size ; 
    //   }) ; 
    // } ,
    // 获取所有文章 async
     getBlogsList(){
      const _this = this ; 
      this.$axios.post("/getBlogsList"  ,this.queryInfo).then(res => {
        console.log(res);
        this.tableData = res.data.data.data ;
        this.total = res.data.data.numbers ; 
      }) ; 

      // const{data:res} = await this.$axios.post("getBlogsList" , {params: this.queryInfo}) ;  
      // this.tableData = res.data.data ;
      // this.total = res.data.numbers ; 
    } , 
    // 分页组件中的 页面监听方法，当显示最大数量发生变化时执行
    handleSizeChange(newSize){  
      this.queryInfo.pageSize = newSize ; 
      this.getBlogsList() ;   // 发生变化后重新加载列表
    } ,
    // 分页组件中的方法，pageNum触发动作，当页码发生变化时执行
    handleCurrentChange(newPage){
      this.queryInfo.pageNum = newPage ; 
      this.getBlogsList() ; 
    }  , 
    // 格式化表格消息内容
    stateFormat(row, column, cellValue) {
      // console.log(row , column , cellValue)
      if (!cellValue) return "";
      if (cellValue.length > 60) {
        //最长固定显示4个字符
        return cellValue.slice(0, 60) + "...";
      }
      return cellValue;
    },
    // 编辑博客
    handleEdit(index, row) {
      if(this.$store.state.userInfo){
        this.showAddBlogDialog = !this.showAddBlogDialog ; 
        this.ruleFormAdd = row;
        console.log(index, row);

      }else{
        this.$message.error("请先登录~") ; 
      }
    },
    // 删除文章
    handleDelete(index, row) {
      if(this.$store.state.userInfo){
        // console.log(index, row.id);
        this.$confirm('是否删除该文章?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post("/blog/delete/" + row.id).then(res => {
            this.$message.success("删除成功") ; 
            this.getBlogsList() ; 
          }) ; 
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // 修改文章状态的方法
    changeSwitch(row,index){

      console.log(row);
      this.ruleFormAdd.id = row.id ; 
      this.ruleFormAdd.title = row.title ; 
      this.ruleFormAdd.description = row.description ; 
      this.ruleFormAdd.context = row.context ; 
      this.ruleFormAdd.status = row.status ; 

      let word = row.status== "0" ? "隐藏" : "显示"  ;
      if(this.$store.state.userInfo){
        this.$confirm('是否'+ word +'该博客?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post("/modifyBlogStatus" , this.ruleFormAdd).then(res => {
            this.$message.success("操作成功") ; 
            // this.getBlogsList() ; 
          }) ; 
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
      }else{
        this.$message.error("请先登录~");
      }
    } , 
  } , 
  created(){
    this.getBlogsList() ; 
    // addblog
    const _this = this ; 
    const blogId = this.$route.params.blogId ; 
    // console.log(blogId);
    if (blogId) {
      this.$axios.get('/blog/' + blogId ).then(res => {
        const blog = res.data.data ; 
          _this.ruleFormAdd.id = blog.id ; 
          _this.ruleFormAdd.title = blog.title ; 
          _this.ruleFormAdd.description = blog.description ; 
          _this.ruleFormAdd.context = blog.context ; 
      })
    }
  }
}
</script>


<style scoped>
.wrapper .card-1{
  width: 100%;
  /* height: 85vh; */
  /* padding-bottom: 20rem; */
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.header{
  font-size: 1rem;
  border-bottom: 1px solid rgb(219, 218, 218);
  box-sizing: content-box;
}
/* 表单查询 */
.demo-ruleForm{
  width: 100%;
  height: 100%;
  /* background-color: pink; */
}
.demo-ruleForm .form-1{
  margin-top: 3.5rem;
  display: flex;
  padding-right: 10rem;
}
.demo-ruleForm .form-1 .form-item{
  flex: 1;
}
.demo-ruleForm .form-2{
  /* background-color: aquamarine; */
  width: 72rem;
}
.demo-ruleForm .query-btn{
  position: absolute;
  display: flex;
  left: 60rem;
  top: 0;
}

/* 表格区域 */
.item-wrap{
  width: 100%;
  height: 100%;
  /* background-color: pink; */
  padding: 1rem;
  margin: 0 auto;
}

.page-wrap{
  width: 100%;
  height: 100%;
  /* background-color: aquamarine; */
  margin: 0 auto;
}
.page{
  margin-top: .9rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

/* 新增blog */
.addBlog{
  position: absolute;
  top: -3rem;
  left: 33rem;
  z-index: 99;
}
.content{
  width: 83rem;
  /* height: 100vh; */
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  padding: 10px 50px 10px 0;
  background-color: white;
  border:1px solid #ccc;
  box-shadow: .5rem 2rem 3rem rgba(0, 0, 0, .3);
}

</style>